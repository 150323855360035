import { API } from 'project/api'
import { getStore } from 'common/store'
import { service } from 'common/service'
import { getServices } from './useService'
import { Req } from 'common/types/requests'
import { Res } from 'common/types/responses'
import { getPropertyAddress } from './usePropertyAddress'

export const userService = service
  .enhanceEndpoints({ addTagTypes: [] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query<Res['user'], Req['getUser']>({
        query: (q) => ({
          url: `customer/me`,
        }),
      }),

      login: builder.mutation<{}, Req['login']>({
        query: (query: Req['login']) => ({
          body: query,
          method: 'post',
          url: `/customer/login`,
        }),
        transformResponse: async (res) => {
          await API.cookies.setCookie('userToken', `${Date.now()}`)
          await getPropertyAddress(getStore(), {})
          await getUser(getStore(), {})
          await getServices(getStore(), {})
          return {}
        },
      }),

      logout: builder.mutation<{}, Req['logout']>({
        queryFn: async (args, { dispatch }, _2, baseQuery) => {
          await baseQuery({
            method: 'post',
            url: `customer/logout`,
          })
          API.logout()
          dispatch(service.util.resetApiState())
          return { data: {} }
        },
      }),

      register: builder.mutation<{}, Req['register']>({
        query: (query: Req['register']) => ({
          body: query,
          method: 'post',
          url: `/customer/register`,
        }),
      }),

      registerToken: builder.mutation<{}, Req['registerToken']>({
        query: (query: Req['registerToken']) => ({
          body: query,
          method: 'post',
          url: `/customer/register`,
        }),
        transformResponse: async (res: Res['oneTimeCodeToken']) => {
          await API.storage.setItem('userToken', res.token)
          await getPropertyAddress(getStore(), {})
          await getUser(getStore(), {})
          await getServices(getStore(), {})
          return { token: res.token }
        },
      }),
      updateProfile: builder.mutation<{}, Req['updateProfile']>({
        queryFn: async (query, { dispatch }, _2, baseQuery) => {
          const { data, error } = await baseQuery({
            body: query,
            method: 'POST',
            url: `/customer/full-account-update`,
          })
          if (error?.data) {
            return { error: error?.data }
          }
          const res = (await dispatch(
            userService.endpoints.getUser.initiate({}, { forceRefetch: true }),
          )) as any
          return { data: res.data, error: res.error }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export const useUser = userService.endpoints.getUser.useQuery
export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useRegisterTokenMutation,
  useUpdateProfileMutation,
  // END OF EXPORTS
} = userService

export async function logout(
  store: any,
  data: Req['logout'],
  options?: Parameters<typeof userService.endpoints.logout.initiate>[1],
) {
  return store.dispatch(userService.endpoints.logout.initiate(data, options))
}

export async function register(
  store: any,
  data: Req['register'],
  options?: Parameters<typeof userService.endpoints.register.initiate>[1],
) {
  return store.dispatch(userService.endpoints.register.initiate(data, options))
}

export async function registerToken(
  store: any,
  data: Req['registerToken'],
  options?: Parameters<typeof userService.endpoints.registerToken.initiate>[1],
) {
  return store.dispatch(
    userService.endpoints.registerToken.initiate(data, options),
  )
}

export async function updateProfile(
  store: any,
  data: Req['updateProfile'],
  options?: Parameters<typeof userService.endpoints.updateProfile.initiate>[1],
) {
  return store.dispatch(
    userService.endpoints.updateProfile.initiate(data, options),
  )
}

export async function getUser(
  store: any,
  data: Req['getUser'],
  options?: Parameters<typeof userService.endpoints.getUser.initiate>[1],
) {
  return store.dispatch(userService.endpoints.getUser.initiate(data, options))
}
